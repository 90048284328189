export default [
  {
    pk: 1,
    page_name: 'Ruta incans pulmón',
    indicators: [
      /* {
        name: 'Pulmón',
        uuid: ''
      } */
    ]
  },
  {
    pk: 2,
    page_name: 'Tamizaje pulmón',
    indicators: [
      /* {
        name: 'Pulmón',
        uuid: ''
      } */
    ]
  },
] as WorkUnit[];
